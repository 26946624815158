import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				The Burrow Tattoo
			</title>
			<meta name={"description"} content={"Imagine. Ink. Inspire. Come to The Burrow Tattoo, where your stories are painted in ink."} />
			<meta property={"og:title"} content={"The Burrow Tattoo"} />
			<meta property={"og:description"} content={"Imagine. Ink. Inspire. Come to The Burrow Tattoo, where your stories are painted in ink."} />
			<meta property={"og:image"} content={"https://luminaraco.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luminaraco.com/images/0032342.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luminaraco.com/images/0032342.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://luminaraco.com/images/0032342.png"} />
			<meta name={"msapplication-TileImage"} content={"https://luminaraco.com/images/0032342.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Our Story
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					The Burrow Tattoo Tattoo Parlor was founded on the principle of transforming personal stories into vivid, permanent art. Our journey began with a simple mission: to elevate tattooing to an art form that respects the individuality of each client and celebrates the diversity of their inspirations. Over the years, we have cultivated a space where creativity flourishes and client visions are realized with utmost care and artistry.
				</Text>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://luminaraco.com/images/2.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Our Artists
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						At the heart of The Burrow Tattoo are our artists – each bringing their own unique style and expertise to the studio. They are not just tattoo artists – they are pioneers of modern tattoo art, continually pushing the boundaries of design and technique. Our team is committed to personal growth and artistic excellence, ensuring that every piece they ink is both a personal and artistic breakthrough.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Why The Burrow Tattoo?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Bespoke Artistry: Each tattoo is tailor-made, designed to complement your vision and body perfectly.
						<br/><br/>
						Advanced Technique: We employ the latest in tattoo technology and artistic techniques to ensure precision and quality.
						<br/><br/>
						Artistic Integrity: Our artists are dedicated to ethical and creative tattooing, focusing on your comfort and satisfaction.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Join Our Canvas
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Your story is unique, and your tattoo should be too. At The Burrow Tattoo, we invite you to be part of a creative journey that honors your personal narrative through exquisite, custom ink. Connect with us and see how your ideas can come to life under the skilled hands of our artists.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="30%"
						md-width="100%"
					>
						Get In Touch
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="70%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Step into The Burrow Tattoo and leave with a masterpiece – Your skin, our canvas.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});